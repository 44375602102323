.ant-modal-content {
  background-color: #F6F8F9 !important;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ant-modal-header {
  color: #3e3e3e;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: poppinsMedium;
  margin-bottom: 17px !important;
  background-color: #F6F8F9 !important;
}