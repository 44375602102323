.notify-popover {
    z-index: 1111 ;
}
.notify-popover-arrow .popover-arrow{
    border: 1px solid #ECECEC;
    top: 1.2px !important;
}
.main-nav
{
    z-index: 990;
}
.version-modal-design {

}
.version-modal-design h3 {
    text-align: left;
    font-size: 22px;
    font-weight: 600;
}
.version-modal-design .version-update-date-box {
    display: flex;
}
.version-modal-design .version-update-date-box p {
    
}
@media only screen and (max-width: 700px) {
     .notify-popover {
        left: -12px !important;
    }
    .notify-popover-arrow {
        padding: 15px 0 !important;
    }
    
  }