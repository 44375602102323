.input-text-custom::-webkit-input-placeholder {
    text-align: center;
  }
  
  .input-text-custom{
    text-align: center;
  }
  .video-camera-box, .video-camera-box video {
    background-color: transparent;
    background: transparent;
  }

  
.img-center,
.block-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
  .selfie-box {
    text-align: center;
    width: 304px;
    height: 304px;
}

.selfie-box video,
.selfie-box img {
    width: 304px;
    height: 304px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #00FFC1;
}

@media (max-width:575px){
  .selfie-box video,
  .selfie-box img {
    width: 270px;
    height: 270px;
  }
}
