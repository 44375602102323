@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "poppinsLight";
  src: url("./assets/fonts/poppinsLight.ttf");
}
@font-face {
  font-family: "poppinsRegular";
  src: url("./assets/fonts/poppinsRegular.ttf");
}
@font-face {
  font-family: "poppinsMedium";
  src: url("./assets/fonts/poppinsMedium.ttf");
}
@font-face {
  font-family: "poppinsSemiBold";
  src: url("./assets/fonts/poppinsSemiBold.ttf");
}
@font-face {
  font-family: "poppinsBold";
  src: url("./assets/fonts/poppinsBold.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #F6F8F9;
  /* user-select: none; */
}

div[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

input::placeholder {
  text-transform: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 10px;
  top: 13px;
  width: 18px;
  height: 18px;
  background: url("./assets/png/calendar.png") no-repeat;
}

input[type=file]{
  opacity:0;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: visibility 0.3s;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ABABAB; 
  border-radius: 10px;
  visibility: hidden;
}

.scroll::-webkit-scrollbar-thumb {
  background: #ABABAB; 
  border-radius: 10px;
  visibility: hidden;
}

.scroll:hover::-webkit-scrollbar-track {
  visibility: visible;
}

.scroll:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.ant-select-selection-search-input {
  font-family: poppinsRegular;
  font-weight: 400;
}

.ant-select-selection-item {
  font-family: poppinsRegular !important;
  font-weight: 400;
}

.ant-select-item-option-content {
  font-family: poppinsRegular;
  font-weight: 400;
}

.hamburger-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.form-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-item {
  margin: 10px;
  width: 300px;
}

.form-box {
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
}

.form-content {
  margin-top: 10px;
}
.referral-container {
  font-family: Arial, sans-serif;
  color: #fff;
  background-color: #3a3ad0;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2323a1;
  padding: 10px 20px;
}

.logo {
  height: 50px;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.login-btn,
.open-account-btn {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.login-btn {
  background-color: #5656e6;
  color: #fff;
}

.open-account-btn {
  background-color: #f0a500;
  color: #fff;
}

.main-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  max-width: 600px;
}

.earn-text {
  font-size: 2rem;
  margin: 0;
}

.highlight {
  color: #f0a500;
}

.link-selection {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.link-box {
  display: flex;
  align-items: center;
  gap: -1px;
}

.link-input {
  padding: 10px;
  border: 2px solid #585c58;
  border-radius: 5px;
  width: 80%;
}

.copy-btn {
  padding: 10px 20px;
  background-color: #050506;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.share-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.share-btn {
  padding: 10px;
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp { background-color: #25d366; }
.facebook { background-color: #3b5998; }
.twitter { background-color: #1da1f2; }
.linkedin { background-color: #0077b5; }

.qr-container {
  text-align: center;
}

.qr-code {
  width: 150px;
}
body{
	margin: 0;
	padding: 0;
	font-family:'Poppins',sans-serif;
}
.referral-link-box{
	width: 100%;
	height: auto;
	max-width: 520px;
	margin: auto;
	text-align: center;
	padding: 0 10px;
	box-sizing: border-box;

}
.referral-link-box h4{
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
	margin-bottom: 10px;
}
.referral-link-box p{
	margin: 0;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 26px;
	color: #797979;
}
.referral-link-box .link-group{
	width: 100%;
	height: auto;
	position: relative;
	display: flex;
	border-radius: 10px;
	background: #fff;
	-webkit-box-shadow: 0px 2px 10px 0px rgba(209,209,209,1);
	-moz-box-shadow: 0px 2px 10px 0px rgba(209,209,209,1);
	box-shadow: 0px 2px 10px 0px rgba(209,209,209,1);
	overflow: hidden;
}
.referral-link-box .link-group .form-control{
	width: 100%;
	border: none;
	background: #fff;
	padding: 11px 16px;
	color: #ABABAB;
	height: 47px;
	box-sizing: border-box;
	font-size: 16px;
}
.referral-link-box .link-group .copy-btn{
	background: #5367FC;
	border:none;
	padding: 10px 20px;
	border-radius: 0 10px 10px 0;
	cursor: pointer;
}

.referral-link-box {
  margin-bottom: 180px;
  text-align: center;
}

.ref-link-img {
  height: 150px;
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ref-link-heading {
  font-weight: 600;
}

.ref-link-description {
  margin-bottom: 20px;
}

.radio-group {
  margin-bottom: 20px;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Space between radio buttons if needed */
}

.radio-input {
  margin-right: 8px;
   /* Space between radio button and label text */
}

.link-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  width: 200px;
  margin-right: 10px;
}

.copy-btn {
  background: none;
  border: none;
  cursor: pointer;
}

