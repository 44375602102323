.payment {
    background-color: aqua;
  }
  
  .modalContainer {
    display: none;
  }
  
  .modalContainer.show {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    overflow: auto;
  }
  
  .main {
    width: 90%;
    max-width: 900px;
    background-color: gray;
    padding: 20px;
  }
  
  .popup {
    background-color: #e4ebeb;
    border-radius: 12px;
    padding: 20px;
    margin-top: 800px;
  }
  
  .popup-leader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .headingH1 {
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins;
  }
  
  .x {
    cursor: pointer;
  }
  
  .cartContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .firstBox,
  .secondBox,
  .thirdBox,
  .forthBox,
  .fiveBox,
  .sixBox,
  .sevenBox,
  .eightBox,
  .nineBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
  }
  
  .firstBox {
    width: 98%;
  }
  
  .secondBox {
    width: 100%;
  }
  
  .thirdBox {
    width: 98%;
    font-weight: 600;
  }
  
  .forthBox,
  .fiveBox,
  .sixBox,
  .sevenBox,
  .eightBox,
  .nineBox {
    width: 100%;
  }
  
  .firstBox-P1 {
    font-family: Poppins;
    font-weight: 600;
    display: flex;
    gap: 331px;
    font-size: 16px;
  }
  
  .firstBox p {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins;
  }
  
  .firstBox-P2 {
    display: flex;
    gap: 275px
  }
  
  .secondBox-p2 {
    display: flex;
    gap: 447px;
  }
  
  .secondBox-p3 {
    display: flex;
    gap: 365px;
  }
  
  .thirdBox-p1 {
    display: flex;
    gap: 325px
  }
  
  .forthBox-p2 {
    display: flex;
    gap: 259px;
  }
  
  .fiveBox-p2 {
    display: flex;
    gap: 261px;
  }
  
  .sixBox-p2 {
    display: flex;
    gap: 466px;
  }
  
  .sixBox-p3 {
    display: flex;
    gap: 331px;
  }
  
  .sevenBox-p2 {
    display: flex;
    gap: 457px;
  }
  
  .sevenBox-p3 {
    display: flex;
    gap: 442px;
  }
  
  .secondBox-p4 {
    display: flex;
    gap: 467px;
  }
  
  .eightBox-P1 {
    display: flex;
    gap: 334px;
    font-weight: 600;
  }
  
  .eightBox-p2 {
    display: flex;
    gap: 288px;
  }
  
  .eightBoxSpan1 {
    display: flex;
    gap: 20px;
  }
  
  .eightBoxSpan2 {
    display: flex;
    gap: 61px;
  }
  
  .nineBox-p2 {
    display: flex;
    gap: 421px;
  }
  
  .nineBox-p3 {
    display: flex;
    gap: 429px;
  }
  
  .nineBox-p4 {
    display: flex;
    gap: 311px;
  }
  
  .nineBox-p5 {
    display: flex;
    gap: 373px;
  }
  
  .nineBox-p6 {
    display: flex;
    gap: 392px;
  }
  
  .nineBox-p7 {
    display: flex;
    gap: 297px;
  }
  
  .nineBox-p8 {
    display: flex;
    gap: 305px;
  }
  
  .nineBox-p9 {
    display: flex;
    gap: 233px;
  }
  
  .nineBox-p10 {
    display: flex;
    gap: 296px;
  
  }
  
  .nineBox-p11 {
    display: flex;
    gap: 350px;
  }
  
  @media screen and (max-width: 390px) {
    .popup {
      margin-top: 1047px;
    }
  
    .firstBox-P1 {
      gap: 65px;
    }
  
    .firstBox-P2 {
      gap: 11px;
    }
  
    .secondBox-p2 {
      gap: 140px;
    }
  
    .secondBox-p3 {
      gap: 37px;
    }
  
    .thirdBox-p1 {
      gap: 48px;
    }
  
    .forthBox-p2 {
      gap: 20px;
    }
  
    .fiveBox-p2 {
      gap: 8px;
    }
  
    .sixBox-p2 {
      gap: 132px;
    }
  
    .sixBox-p3 {
      gap: 27px;
    }
  
    .sevenBox-p2 {
      gap: 143px;
    }
  
    .sevenBox-p3 {
      gap: 131px;
    }
  
    .sevenBox-p4 {
      gap: 157px;
    }
  
    .eightBox-P1 {
      gap: 4px;
    }
  
    .eightBox-p2 {
      gap: 1px;
    }
  
    .eightBoxSpan2 {
      gap: 30px;
    }
  
    .eightBoxSpan1 {
      gap: 0px;
    }
  
    .nineBox-p2 {
      gap: 133px;
    }
  
    .nineBox-p3 {
      gap: 142px;
    }
  
    .nineBox-p4 {
      gap: 26px;
    }
  
    .nineBox-p5 {
      gap: 89px;
    }
  
    .nineBox-p6 {
      gap: 110px;
    }
  
    .nineBox-p7 {
      gap: 14px;
    }
  
    .nineBox-p8 {
      gap: 25px;
    }
  
    .nineBox-p9 {
      gap: 61px;
    }
  
    .nineBox-p10 {
      gap: 14px;
    }
  
    .nineBox-p11 {
      gap: 66px;
    }
  
  }
  
  @media screen and (max-width: 441px) {
    .popup {
      margin-top: 1047px;
    }
  
    .firstBox-P1 {
      gap: 65px;
    }
  
    .firstBox-P2 {
      gap: 11px;
    }
  
    .secondBox-p2 {
      gap: 140px;
    }
  
    .secondBox-p3 {
      gap: 37px;
    }
  
    .thirdBox-p1 {
      gap: 48px;
    }
  
    .forthBox-p2 {
      gap: 20px;
    }
  
    .fiveBox-p2 {
      gap: 8px;
    }
  
    .sixBox-p2 {
      gap: 132px;
    }
  
    .sixBox-p3 {
      gap: 27px;
    }
  
    .sevenBox-p2 {
      gap: 143px;
    }
  
    .sevenBox-p3 {
      gap: 131px;
    }
  
    .sevenBox-p4 {
      gap: 157px;
    }
  
    .eightBox-P1 {
      gap: 4px;
    }
  
    .eightBox-p2 {
      gap: 1px;
    }
  
    .eightBoxSpan2 {
      gap: 30px;
    }
  
    .eightBoxSpan1 {
      gap: 0px;
    }
  
    .nineBox-p2 {
      gap: 133px;
    }
  
    .nineBox-p3 {
      gap: 142px;
    }
  
    .nineBox-p4 {
      gap: 26px;
    }
  
    .nineBox-p5 {
      gap: 89px;
    }
  
    .nineBox-p6 {
      gap: 110px;
    }
  
    .nineBox-p7 {
      gap: 14px;
    }
  
    .nineBox-p8 {
      gap: 25px;
    }
  
    .nineBox-p9 {
      gap: 61px;
    }
  
    .nineBox-p10 {
      gap: 14px;
    }
  
    .nineBox-p11 {
      gap: 66px;
    }
  
  }
  
  
  