.loader-nominee-box {
    width: 100%;
}
.loader-nominee-box img {
    display: inline-block;
    max-width: 30px;
}
.my-filter-popover {
    width: auto;
    top: 13px !important;
    left: -55px !important;
    background: #FAFAFA;
    border: 1px solid #ECECEC;
    border-radius: 10px;
}
.filter-arrow{
    top: -14px !important;
    left: 129px !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 15px solid #FAFAFA !important;
}
.column-row {
    width: 100%;
    display: flex;
}
.column-row .column-col-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
}
.w-full.main-nav.flex.fixed {
    /* position: absolute; */
}
.font-18 {
    font-size: 18px;
}
.zi-9000 {
    z-index: 991;
}
.sidebar-toggle-btn {
    width: 32px;
    height: 32px;
    background-color: #333;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 30px;
    border: 1px solid #333;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 30px;
    bottom: 40px;
}
.sidebar-toggle-btn svg {
    display: block;
    margin: 3px auto 0;
    transform: rotate(90deg);
}
.sidebar-menu-list.active .sidebar-toggle-btn {
    margin-left: 0px;
}
.sidebar-menu-list.active .sidebar-toggle-btn svg {
    transform: rotate(-90deg);
}
 
.sidebar-menu-list.active {
    width: 97px;
    max-width: 97px;
}
.sidebar-menu-list.active ul > li span, .sidebar-menu-list.active ul > li ul, 
.sidebar-menu-list.active ul > li svg {
    display: none;
    transition: 0.4s;
}

.sidebar-menu-list.active + .w-full {
    margin-left: 70px;
}
.sidebar-menu-list.active + .w-full .w-full.tittle-box > div {
   margin-left: 6px;
}

.refetch-button {
    display: flex;
    justify-content: center;
    align-items: center;  
    max-width: 170px;  
    font-size: 12px;
}
.refetch-button img {
    width: 11px;
}
.text-danger {
   color: red;
   font-weight: 500;
}
.title-rejected-page > div {
    font-size: 24px;
}
.font-16 {
    font-size: 16px;
}
.font-500 {
    font-weight: 500;
}
.font-600 {
    font-weight: 600;
}
.rejected-otem-list {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 12px 15px;
    margin-bottom:20px;
}
.rejected-otem-list .mb-5 {
    margin-bottom: 0rem;
}
.rejected-otem-list .true .flex-col  > div.text-md.font-medium.mb-2 {
    margin-bottom: 0rem;
}
.d-block {
    display: block;
}
.btn-danger-rejected {
    color: #fff;
    background-color: #f71d1d;
    border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    transition: 0.3s;
}
.btn-danger-rejected:hover,
.btn-danger-rejected:focus {
   opacity: 0.8;
   transition: 0.3s;
}
.btn-normal {
    padding: 5px 10px;
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
}
.custom-accordian-ec {
    width: 100%;
}
.custom-accordian-ec div label {
    display: none!important;
}
.custom-accordian-ec div label.d-block-label {
    display: block!important;
}
.col-md-date-picker  {  
    position: relative;
 }
.col-md-date-picker div {
   display: block;
   width: 100%;
   position: relative;
}
.col-md-date-picker div input {
   padding-left: 50px;
 }
 .col-md-date-picker .col-dob {
    left: 25px;
    top: 17px;
    position: absolute;
    z-index: 99;
    color: #ccc;
    display: none;
 } 
 .col-md-date-picker .ant-picker-input {
    margin-top: 15px;
 }
 .nominee-yesno {

 }
 .nominee-yesno label {
    
 }
 .nominee-yesno span {
    
 }
@media(min-width:992px) {
    .ccustom-row {
       display: flex;
       margin-left: -15px;
       margin-right: -15px;
       flex-wrap: wrap;
    }
    .ccustom-row .col-md-6 {
       width: 50%;
       max-width: 50%;
       flex: 0 0 50%;
       padding-left: 15px;
       padding-right: 15px;
       margin-top: 0 !important;
       margin-bottom: 20px;
     }
     .ccustom-row .col-md-12 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0 !important;
        margin-bottom: 20px;
      }
     .ccustom-row .col-md-6 input,
     .ccustom-row .col-md-6 select,
     .ccustom-row .col-md-4 input,
     .ccustom-row .col-md-4 select {
        width: 100%;
     }
     .ccustom-row .col-md-4 {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0 !important;
        margin-bottom: 20px;
      }
}