* {
    margin: 0;
    padding: 0;


}

.address-page .form-input{
   
    margin:auto;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
   
}
.address-page .form-control,
.address-page .form-select {   
    margin-bottom: 20px;
    border: none;
    background: #FFF;  
    height: 50px; 
    box-shadow: 0 0 5px rgb(174 174 174 / 31%);
    padding: 15px;
    border: 1px solid #eee;
    
}
.address-page .radius input{
    border-radius: 10px;
   
}


.address-page .custom-select-wrapper {
  width: 100%;
  /* user-select: none; */
   margin-bottom: 20px;
  box-shadow: 0px 1px 12px 0px rgba(185, 185, 185, 0.25);
  height: 50px;
  border-radius: 10px;
  z-index: 99;
  border: 1px solid #ddd;
  font-weight: 400;
}

.address-page .custom-select-wrapper .default-op {
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  color: var(--light-grey);
  list-style: none;
  padding: 10px;
}

.address-page .custom-select-wrapper .default-op:before {
  content: "";
  position: absolute;
  top: 20px;
  right: 15px;
  width: 8px;
  height: 8px;
  border: 1.5px solid var(--light-grey);
  border-color: transparent transparent var(--light-grey) var(--light-grey);
  transform: rotate(-45deg);
}

.address-page .custom-select-wrapper .select-options {
  position: relative;
  top: -70%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  
  display: none;
  list-style: none;
  z-index: 99;
  padding: 0;
}

.address-page .custom-select-wrapper .select-options li {
  list-style: none;
  color: var(--light-grey);
  cursor: pointer;
  padding: 8px;
}

.address-page .custom-select-wrapper .select-options li:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.address-page .custom-select-wrapper .select-options li:hover {
  background: whitesmoke;
  border-radius: 10px;
  color: #212529;
}



.address-page .custom-select-wrapper.active .select-options {
  display: block;

}

.address-page .custom-select-wrapper.active .default-op:before {
  top: 50%;
  transform: rotate(-225deg);
}



  