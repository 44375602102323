.msg_wrapper{
    width: 100%;
    height: auto;
    padding-top: 60px;
}
.msg_wrapper img{
    max-width: 100%;
    max-height: 100%;
}
.msg_wrapper p{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #231f23;
    margin-top: 10px;
}